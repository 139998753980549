import React from 'react';
import ReactMarkdown from 'react-markdown';
import './Chatbox.css';

function ChatMessage({ message, from }) {
    return (
            <ReactMarkdown className="message" children={message}/>
    );
}

export default ChatMessage;