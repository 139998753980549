import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Chatbox.css';
import ChatMessage from './ChatMessage';
import config from '../config';


function Chatbox() {
    const [message, setMessage] = useState('');
    const [conversation, setConversation] = useState([]);
    const [thread_id, setThread_id] = useState('new');
    const [chatEnabled, setChatEnabled] = useState(true);
    const endOfMessagesRef = useRef(null);
    const name = "AI Chat";
    const backendIp = config.backendIp;

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]); // Dependency array includes `messages`, so it runs every time `messages` changes


    const sendMessage = async (e) => {
        setChatEnabled(false);
        e.preventDefault();
        const tempMessage = message;
        setMessage('');

        // Update the conversation with the user's message
        setConversation(conversation => [...conversation, { msg: tempMessage, from: 'user' }, { msg: `Please wait, ${name} is thinking...`, from: 'bot' }]);
        
        try {
            const response = await axios.post(`${backendIp}/ask`, {thread_id: thread_id, message: tempMessage });

            //check if response is good
            if (response.status !== 200) {
                throw new Error(`Unexpected response code: ${response.status}`);
            }
    
            // Update the conversation by replacing the "typing" message with the actual response
            setConversation(conversation => conversation.map((item, index, arr) => 
                index === arr.length - 1 ? { msg: response.data.response, from: 'bot' } : item
            ));
            setThread_id(response.data.thread_id);
        } catch (error) {
            // Handle any errors, for example by showing an error message in the UI
            console.error("Error when sending message: ", error);
            setConversation(conversation => conversation.map((item, index, arr) => 
                index === arr.length - 1 ? { msg: "Failed to get response.", from: 'bot' } : item
            ));
        }
        setChatEnabled(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();  // Prevent the default action to avoid a new line
            sendMessage(event);
        }
    };

    const clearChat = (e) => {
        e.preventDefault();
        setConversation([]);
        setThread_id('new');
        localStorage.removeItem('thread_id');
    }

    return (
        
        <div className="chat-container">  
            <div className="chat-header"> 
                <img className = "logo" src='main-logo.png' alt="PSEL Logo"></img>
                <button type="reset" onClick={(e) => clearChat(e)}>Clear Chat</button>
            </div>
            <div className="chat-body">
                {conversation.length === 0 ? (
                <div className='intro'>
                    <p>Welcome to the PSEL AI Chat. Submit a message below for assistance.</p>
                </div>): <div className='buffer'></div>}
                {conversation.map((entry, index) => (
                    <div key={index} className={`chat-message ${entry.from === 'user' ? 'user' : 'bot'}`}>
                        <ChatMessage message={entry.msg} from={entry.from} />
                    </div>
                ))}
                {/* Empty div that acts as the scroll target */}
                <div ref={endOfMessagesRef} />
            </div>
            <form onSubmit={sendMessage}>
                    {/* <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type a message..." /> */}
                    <p className='disclaimer'><em>Note: AI Chat is experimental, AI can give false information</em></p>
                    <div className='chat-input'>
                    <textarea disabled={!chatEnabled} value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type a message..." onKeyDown={(e) => handleKeyDown(e)} />
                    <button disabled={!chatEnabled} type="submit">Send</button>
                    </div>
            </form>
            

        </div>
    );
}

export default Chatbox;