
import Chatbox from './components/Chatbox';
import './App.css';


function App() {
  return (
    <div className="App">
      <Chatbox />
    </div>
  );
}

export default App;
